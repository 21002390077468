import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mixpanel from 'mixpanel-browser';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Box } from '@mui/material';
import App from './App';
import Error from './components/Error';
import Layout from './components/shared/Layout';
import Header from './components/shared/Layout/Header';
import Loading from './components/shared/Loading';
import SentryErrorWrapper from './components/shared/SentryErrorWrapper';
import reportWebVitals from './reportWebVitals';
import Theme from './theme';

dayjs.extend(utc);
dayjs.extend(tz);

Sentry.init({
  dsn: 'https://2caea539a6898088945b4bca8ef7fb6c@o27727.ingest.sentry.io/4505943391731712',
  environment: process.env.ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /solutions\.invocacdn\.com\//i,
    /bat\.bing\.com\//i,
    /shopper\.shop\.pe\//i,
    /s3-us-west-2\.amazonaws\.com\/b2bjsstore\//i,
    /analytics\.tiktok\.com\//i,
  ],
  beforeSend: (event, hint) => {
    console.error({
      event,
      hint,
      query: window?.location?.search,
      originalException: hint?.originalException ?? hint?.syntheticException,
    });
    return event;
  },
});

const sentryCreateBrowswerRoute = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
  record_sessions_percent: process.env.REACT_APP_ENVIRONMENT === 'production' ? 100 : 0,
  record_block_selector: 'video',
  record_collect_fonts: true,
  record_mask_text_selector: '',
});

const queryClient = new QueryClient();

const ErrorElement = () => (
  <SentryErrorWrapper>
    <Error />
  </SentryErrorWrapper>
);

const withSuspense = (Component) => (props) => (
  <Suspense fallback={<Loading />}>
    <Component {...props} />
  </Suspense>
);

const router = sentryCreateBrowswerRoute(
  [
    {
      element: <App queryClient={queryClient} />,
      children: [
        {
          element: <Layout />,
          // Use SentryErrorWrapper for any custom `errorElement` designed for special routes
          // This wrapper ensures any error thrown in loaders/actions gets logged to sentry
          errorElement: <ErrorElement />,
          children: [
            {
              path: 'entry',
              lazy: () =>
                import('./routes/entry').then(({ loader, ...rest }) => ({
                  loader: loader(),
                  ...rest,
                })),
            },
            {
              path: 'redirecting',
              lazy: () => import('./routes/redirecting'),
            },
            {
              path: ':step',
              lazy: () =>
                import('./routes/step').then(({ action, loader, Component }) => ({
                  action: action(queryClient),
                  loader: loader(queryClient),
                  Component: withSuspense(Component),
                })),
            },
          ],
        },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL,
  },
);

const FallbackElement = () => (
  <Theme>
    <QueryClientProvider client={queryClient}>
      <Box minHeight={{ xs: 'calc(100vh - 51px)', md: 'calc(100vh - 105px)' }} display="flex" flexWrap="wrap">
        <Header />
        <Box
          width="100%"
          height="100%"
          pt={{ xs: 10, md: 24 }}
          pb={{ xs: 20, sm: 10, md: 20 }}
          px={{ xs: 10 }}
          bgcolor="background.default"
          display="flex"
          flexDirection="column"
          textAlign="center"
        >
          <Loading text="Loading" />
        </Box>
      </Box>
    </QueryClientProvider>
  </Theme>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<FallbackElement />} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
