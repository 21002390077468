import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { bool, element } from 'prop-types';
import { useLocation, useSearchParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

type Analytics = {
  track: Function;
  identify: Function;
  userId: string;
  ready: boolean;
};

export const AnalyticsContext = createContext<Analytics>({
  track: null,
  identify: null,
  userId: null,
  ready: false,
});

const AnalyticsProvider = ({ enabled, children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();
  const [userId, setUserId] = useState(null);
  const analyticsInitialized = useRef(false);

  const track = async (title, dataObject, ctx = {}) => {
    if (analytics?.track) {
      let id;
      try {
        id = searchParams.get('irclickid');
      } catch {
        id = null;
      }

      const mpSessionDetails = mixpanel.get_session_recording_properties();

      const context = {
        context: {
          ...ctx,
          ...(id && {
            referrer: {
              type: 'impactRadius',
              id,
            },
          }),
        },
      };
      await analytics.track(title, { ...dataObject, ...(mpSessionDetails ?? {}) }, context);
    }
  };

  const data = useMemo(
    () => ({ track, identify: analytics?.identify, userId, ready: !enabled || (analyticsInitialized?.current && !!userId) }),
    [analytics, userId, analyticsInitialized.current, enabled],
  );

  useEffect(() => {
    if (enabled && !analytics && !analyticsInitialized.current) {
      analyticsInitialized.current = true;
      const analyticsObj = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });
      analyticsObj.page();
      setAnalytics(analyticsObj);
      analyticsObj.user().then((user) => {
        setUserId(user.anonymousId());
      });
    }
    if (!enabled) {
      setUserId(crypto.randomUUID());
    }
  }, [analytics]);

  useEffect(() => {
    if (enabled && analytics?.page) {
      analytics.page();
    }
  }, [location]);

  return <AnalyticsContext.Provider value={data}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.propTypes = {
  enabled: bool.isRequired,
  children: element,
};

AnalyticsProvider.defaultProps = {
  children: null,
};

export default AnalyticsProvider;
